<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData">
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 50px;">

				<a-col :span="24" :md="16" :lg="14" :xl="14" class="col-form">
					
					<a-card :bordered="false" class="px-20 mx-0 pt-20">
						<a-row :gutter="[24,24]" class="px-20">
							<a-col :span="24" class="px-20 pt-20 mt-10">
								<a-row class="mt-0 pt-2 mb-20 px-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 text-left">
										<h4>{{ formatName(classDetails.name) }}</h4>
									</a-col>
									
									<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-10 pl-5 text-left">
										<label>📖 Subject: <strong>{{ classDetails.subjects.length > 0 ? classDetails.subjects[0].name : 'Unknown' }}</strong> </label>
									</a-col>

                                    <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-15 pl-5 text-left">
										<label>⏳ Duration: <strong>{{ classDetails.duration }} minutes</strong></label>
									</a-col>

                                    <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 pl-5 text-left">
                                        <a-button id="lightBtn" type="text" class="" style="height: 30px">{{ classDetails.educationGrades.length > 0 ? classDetails.educationGrades[0].name : 'Unknown' }}</a-button>
									</a-col>

									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5">
										<label><strong>Instructions</strong></label>
									</a-col>
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-10 mb-24">
										<ul>
											<li class="" v-if="classDetails.instruction != null && classDetails.instruction.length > 0 "><p>{{ formatName(classDetails.instruction) }}</p></li>
											<li><p>This quiz consist of <strong>{{ classDetails.questionsCount }} questions</strong> </p></li>
											<li><p>You have <strong>{{ classDetails.duration }} minutes</strong> to complete quiz </p></li>
										</ul>
									</a-col>
								</a-row>


								<a-row class="mt-0 pt-10 mb-20 px-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<a-button :loading="classDetails.loading || joinClass.loading" type="primary" style="width: 100%" @click="handleJoinQuiz"> 
                                            {{ classDetails.isFree ? 'Start Quiz for Free' :  `Pay TZS ${classDetails.price} to do Quiz`}}
                                        </a-button>					
									</a-col>

									<!-- <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<a-button id="btnBordered" type="primary" style="width: 250px" @click="copyUrl">
											<svg class="dark:text-white" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m8 10.9 7-3.2m-7 5.4 7 3.2M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
											</svg> 
											Share Quiz 
										</a-button>					
									</a-col> -->
								</a-row>
								
							</a-col>
						</a-row>

					</a-card>
				</a-col>

			</a-row>
		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

         <!-- FORM MODAL -->
		<a-modal v-model="paymentNumberShowModal" :footer="null">

			<h4 class="text-center">Add Payment Number</h4>

			<a-form
				:form="form"
				class="login-form"
				@submit="handleSubmitPhoneNumber"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Enter the phone number for payment" :colon="false">
					<a-input 
						class="mt-0"
						v-decorator="['phoneNumber', { rules: [{ required: true, message: 'Please enter phone number!' }, { len: 10, message: 'Please enter a valid phone number' }] },]" 
						placeholder="eg. 0736473546"/>
				</a-form-item>
				
				<a-form-item class="mt-20">
					<a-button type="primary" id="btn" html-type="submit" class="login-form-button text-white">
						Save
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                activeTab: 1,
				classes: [],
                classDetails: {
                    uuid: null,
                    name: null,
                    objective: null,
                    isPublic: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    numOfSlots: null,
                    rating: null,
                    instruction: null,
                    duration: null,
                    creator: {},
                    questionsCount: 0,
                    isAcademic: null,
                    price: 0,
                    isFree: null,
                    isForTeaching: null,
                    features: [],
                    assessmentModes: [],
                    startTimestamp: null,
                    endTimestamp: null,
                    thumbnail: null,
                    createdAt: null,
                    updatedAt: null,
                },

                joinClass: {
                    loading: false,
                },

				currentPage: 1,

                paymentNumberShowModal: false,

                form: this.$form.createForm(this, { name: 'topic_login' }),

                userDetails: {},
      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Quizzes', url: '/quizzes', isActive: false },
                { title: 'Details', url: '', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getUserDetails();
			this.getQuizDetails();
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            formatFetures(name) {
				let formattedName = name.replace(/_/g, ' ');
                let formattedName1 = formattedName.includes('mode') ? formattedName.substring(5) : formattedName;
                return formattedName1[0].toUpperCase() + formattedName1.substring(1);
            },

            toTutorProfilePage(uuid) {
                this.$router.push(`/tutors/view/${uuid}`)
            },


            async getUserDetails() {

				let userInfo = await localStorage.getItem("user_details");

				if(userInfo != null) {
                    this.userDetails = JSON.parse(userInfo);
				}

			},


			async getQuizDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.uuid = response.data.data.uuid;
                        this.classDetails.name = response.data.data.title;
                        this.classDetails.objective = response.data.data.objective;
                        this.classDetails.isPublic = response.data.data.isPublic;
                        this.classDetails.educationSystems = response.data.data.educationSystems;
                        this.classDetails.educationLevels = response.data.data.educationLevels;
                        this.classDetails.educationGrades = response.data.data.educationGrades;
                        this.classDetails.subjects = response.data.data.subjects;
                        this.classDetails.numOfSlots = response.data.data.numOfSlots;
                        this.classDetails.rating = response.data.data.rating;
                        this.classDetails.duration = response.data.data.duration;
                        this.classDetails.instruction = response.data.instruction;
                        this.classDetails.creator = response.data.data.creator;
                        this.classDetails.questionsCount = response.data.data.questionsCount;
                        this.classDetails.isAcademic = response.data.data.isAcademic;
                        this.classDetails.price = response.data.data.price;
                        this.classDetails.isFree = response.data.data.isFree;
                        this.classDetails.isForTeaching = response.data.data.isForTeaching;
                        this.classDetails.features = response.data.data.features;
                        this.classDetails.assessmentModes = response.data.data.assessmentModes;
                        this.classDetails.startTimestamp = response.data.data.startTimestamp;
                        this.classDetails.endTimestamp = response.data.data.endTimestamp;
                        this.classDetails.thumbnail = response.data.data.thumbnail;
                        this.classDetails.createdAt = response.data.data.createdAt;
                        this.classDetails.updatedAt = response.data.data.updatedAt;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

            handlePaymentModal() {
                this.paymentNumberShowModal = true;
            },


            handleSubmitPhoneNumber(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

                        this.paymentNumberShowModal = false;

						let phoneNumber = values.phoneNumber;

                        this.joinClass.loading = true;

                        let paymentDetails = {
                            type: "class",
                            amount: this.classDetails.price,
                            currency: "TZS",
                            isClazz: false,
                            isQuiz: true,
                            isMaterials: false,
                            isBooking: false,
                            item: this.classDetails.uuid,
                            paidBy: this.userDetails.uuid,
                            paidTo: this.classDetails.creator.uuid,
                            redirectTo: `${this.$FRONTEND_URL}/payments/${this.classDetails.uuid}`,
                            phoneNumber: phoneNumber,
                            country: 'TZ',
                            status: "PENDING",
                            card: false,
                        }

        
                        let url = `${this.$BACKEND_URL}/payments/link`;

                        this.$AXIOS.post(url, paymentDetails).then(async(response) => {
                            if (response.status >= 200 && response.status < 210) {

                                await localStorage.setItem('transaction_details', JSON.stringify(response.data.data));

                                this.joinClass.loading = false;

                                window.open(response.data.data.paymentLink, '_blank');
                                
                            }

                        }).catch((err) => {
                            
                            this.joinClass.loading = true;
                            
                            this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                        });

						
					}
				})
			},


            async handleJoinQuiz() {

                if(!this.classDetails.isFree) {

                    this.handlePaymentModal();
                    
                }else {

                    // const userInfo = await localStorage.getItem('user_details')

					// let userDetails = JSON.parse(userInfo);

				    // this.joinClass.loading = true;

				    // let url = `${this.$BACKEND_URL}/members/${this.$route.params.uuid}`;

                    // this.$AXIOS.post(url, {uuid: userDetails.uuid}).then(async(response) => {

                    //     this.notify('You have successfully joined the class', 'success')

                    //     this.joinClass.loading = false;

                    this.$router.push(`/quizzes/view/${this.$route.params.uuid}/start`)

                    // }).catch(async(error) => {
                        
                    //     this.joinClass.loading = false;

                    //     this.newClass.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
                    //     this.newClass.errorShow = true

                    //     if(error.response && error.response.status == 401) {
                    //         await localStorage.setItem("user_token", null);
                    //         await localStorage.setItem("user_details", null)
					// 	await localStorage.setItem("institution_details", null)

                    //         this.$router.push(`/sign-in`);
                    //     }
                    // });
                }

            },
            

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>
